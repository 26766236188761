import axios from "../../utils/axios";
import { put } from 'redux-saga/effects'
import { REPORT , COMPLETE , SEARCH } from "./actionTypes";
import { report_info , final , search } from "../../utils/api";

//报告详情
export function* getReport(active) {
    const result = yield axios(`/kfApi/${report_info}`,active.params)
    const data = result.data;
    yield put({
        type: REPORT.GET_REPORT_INFO,
        data
    }) 
}

//完诊
export function* getComplete(active) {
    const result = yield axios(`/kfApi/${final}`,active.params,'POST')
    const data = result.data;
    yield put({
        type: COMPLETE.POST_COMPLETE_REPORT,
        data
    }) 
}

//相关搜索
export function* getSearch(active) {
    const result = yield axios(`/kfApi/${search}`,active.params)
    const data = {
        type:active.params.turn?active.params.turn:active.params.type,
        data:result.data
    };
    yield put({
        type: SEARCH.GET_SEARCH_ING,
        data
    }) 
}