//登录提交
export const LOGIN = {
    LOGIN_SUBMIT:'LOGIN_SUBMIT',
    POST_LOGIN_SUBMIT:'POST_LOGIN_SUBMIT'
};
//图形验证码
export const CODE = {
    CODE_IMG:'CODE_IMG',
    POST_CODE_IMG:'POST_CODE_IMG'
};
//登录 - 存储变量
export const LOGIN_VALUE = 'LOGIN_VALUE';
