import { SUBSCRIBE , SUBSCRIBE_CANCEL , SUBSCRIBE_UPDATE , STORE , MODIFY , TIMEADD , TIMELIST } from './actionTypes';
import { message } from 'antd';

// 数据池
const defaultState = {
    subscribe_list:[],
    total:0,
    store:[],
    time_list:[],
    show:false,
    show1:false,
    show2:false,
    sys_organizations_id:0
};

var Reducer = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch(action.type){
        case SUBSCRIBE.POST_SUBSCRIBE_LIST:     // 预约列表
            newState.subscribe_list = action.data.list;
            newState.total = action.data.total;
            return newState;
        case SUBSCRIBE_CANCEL.GET_SUBSCRIBE_CANCEL:     // 取消预约
            newState.show = false;
            message.success('操作成功！');
            return newState;
        case SUBSCRIBE_UPDATE.POST_SUBSCRIBE_UPDATE:     // 修改预约
            newState.show1 = false;
            message.success('操作成功！');
            return newState;
        case STORE.GET_STORE_LIST:     // 门店列表
            newState.store = action.data;
            return newState;
        case TIMEADD.POST_TIME_ADD:     // 预约时间添加
            //newState.time_add = action.data;
            message.success('操作成功！');
            setTimeout(function(){
                window.history.go(0);
            },1000);
            return newState;
        case TIMELIST.TIME_LIST:         // 预约时间列表(存值)
            newState.sys_organizations_id = action.params.sys_organizations_id;
            return newState;
        case TIMELIST.POST_TIME_LIST:     // 预约时间列表
            newState.time_list = action.data;
            return newState;
        case MODIFY:                         //修改 状态
            action.params.map((item)=>{
                newState[item.key] = item.value;
            })
            return newState;
        default:
            return state;
    }
};

export default Reducer;
