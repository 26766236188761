// 预约列表
export const SUBSCRIBE = {
    SUBSCRIBE_LIST:'SUBSCRIBE_LIST',
    POST_SUBSCRIBE_LIST:'POST_SUBSCRIBE_LIST'
};

// 取消预约
export const SUBSCRIBE_CANCEL = {
    SUBSCRIBE_CANCEL:'SUBSCRIBE_CANCEL',
    GET_SUBSCRIBE_CANCEL:'GET_SUBSCRIBE_CANCEL'
};

// 更新/修改预约
export const SUBSCRIBE_UPDATE = {
    SUBSCRIBE_UPDATE:'SUBSCRIBE_UPDATE',
    POST_SUBSCRIBE_UPDATE:'POST_SUBSCRIBE_UPDATE'
};

//门店列表
export const STORE = {
    STORE_LIST:'STORE_LIST',
    GET_STORE_LIST:'GET_STORE_LIST'
};

//修改 状态
export const MODIFY = "MODIFY";

//预约时间列表
export const TIMELIST = {
    TIME_LIST:'TIME_LIST',
    POST_TIME_LIST:'POST_TIME_LIST'
};

//预约时间添加
export const TIMEADD = {
    TIME_ADD:'TIME_ADD',
    POST_TIME_ADD:'POST_TIME_ADD'
};