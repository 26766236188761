
//报告详情
export const REPORT = {
    REPORT_INFO:'REPORT_INFO',
    GET_REPORT_INFO:'GET_REPORT_INFO'
};

//修改状态
export const REPORT_STATE = 'REPORT_STATE';

//完诊
export const COMPLETE = {
    COMPLETE_REPORT:'COMPLETE_REPORT',
    POST_COMPLETE_REPORT:'POST_COMPLETE_REPORT'
};

//相关搜索
export let SEARCH = {
    SEARCH_ING:'SEARCH_ING',
    GET_SEARCH_ING:'GET_SEARCH_ING'
}