import axios from "../../utils/axios";
import { subscribe_list , subscribe_cancel , subscribe_update  , store , time_add , time_list } from "../../utils/api";
import { put } from 'redux-saga/effects'
import { SUBSCRIBE , SUBSCRIBE_CANCEL , SUBSCRIBE_UPDATE , STORE , TIMEADD , TIMELIST } from "./actionTypes";

// 预约列表
export function* postSubscribeList(active) {
    const result = yield axios(`/kfApi/${subscribe_list}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SUBSCRIBE.POST_SUBSCRIBE_LIST,
            data
        }) 
    }
}

// 取消预约
export function* getSubscribeCancel(active) {
    const result = yield axios(`/kfApi/${subscribe_cancel}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SUBSCRIBE_CANCEL.GET_SUBSCRIBE_CANCEL,
            data
        }) 
    }
}

// 更新/修改预约
export function* postSubscribeUpdate(active) {
    const result = yield axios(`/kfApi/${subscribe_update}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: SUBSCRIBE_UPDATE.POST_SUBSCRIBE_UPDATE,
            data
        }) 
    }
}

// 门店列表
export function* getStoreList(active) {
    const result = yield axios(`/kfApi/${store}`,active.params);
    if(result.code===1){
        const data = result.data;
        yield put({
            type: STORE.GET_STORE_LIST,
            data
        }) 
    }
}

// 预约时间列表
export function* postTimeList(active) {
    const result = yield axios(`/kfApi/${time_list}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: TIMELIST.POST_TIME_LIST,
            data
        }) 
    }
}

// 预约时间添加
export function* postTimeAdd(active) {
    const result = yield axios(`/kfApi/${time_add}`,active.params,'POST');
    if(result.code===1){
        const data = result.data;
        yield put({
            type: TIMEADD.POST_TIME_ADD,
            data
        }) 
    }
}